import gql from 'graphql-tag';
import jsonwebtoken from 'jsonwebtoken';
import qs from 'query-string';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { isMongoId } from 'validator';
import { useMutation } from '@apollo/react-hooks';
import { Avatar, Container, Typography, makeStyles } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import * as Sentry from '@sentry/browser';
import { Failure } from '../../components/Failure';
import { Loading } from '../../components/Loading';
import { Success } from '../../components/Success';
import { ChangePasswordForm } from './ChangePasswordForm';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const VERIFY_ACCOUNT_MUTATION = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    result: changePassword(input: $input) {
      passwordChanged
      error {
        message
      }
    }
  }
`;

export const ChangePassword = props => {
  const secret = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  }).secret;

  const classes = useStyles();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [changePassword, { loading }] = useMutation(VERIFY_ACCOUNT_MUTATION, {
    onCompleted: data => {
      const { passwordChanged, error: mutationError } = data.result;

      if (passwordChanged) {
        setSuccess(true);
      } else {
        setError(
          new Error(
            (mutationError && mutationError.message) || 'Unknown error',
          ),
        );
      }
    },
    onError: error => {
      Sentry.captureException(error);

      setError(error);
    },
  });

  if (!error) {
    // check if the secret is valid
    try {
      const { userID } = jsonwebtoken.decode(secret);

      if (!isMongoId(userID)) {
        throw new Error('invalid userID');
      }
    } catch (error) {
      return <Redirect to='/forgot' />;
    }
  }

  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        {!success && (
          <>
            <Avatar className={classes.avatar}>
              <Edit />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Change password
            </Typography>
          </>
        )}

        <div className={classes.content}>
          {loading && <Loading />}
          {success && (
            <Success
              title='Password change successful!'
              messages={[
                'Your password has been updated and is effective immediately.',
                'You may now close this page.',
              ]}
            />
          )}
          {error && (
            <Failure
              messages={[error.message]}
              retry={() => {
                this.setState({ error: null, success: false });
              }}
            />
          )}
          {!error && !loading && !success && (
            <ChangePasswordForm
              {...props}
              changePassword={changePassword}
              secret={secret}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
