import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { captureEvent } from '../utils/captureEvent';

function RouteListenerComp({ history }) {
  useEffect(() => {
    captureEvent({
      name: 'PAGE_NAVIGATION',
      data: [
        { key: 'action', value: history.action },
        { key: 'path', value: history.location.pathname },
      ],
    });
  });

  return null;
}

export const RouteListener = withRouter(RouteListenerComp);
