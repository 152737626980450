import React from 'react';
import {
  createStyles,
  withStyles,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import { isEmail } from 'validator';
import { captureEvent } from '../../utils/captureEvent';

const styles = createStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorText: {
    fontSize: '0.8em',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  field: {
    width: '100%',
    position: 'relative',
    paddingBottom: '8px',
    marginBottom: 0,
    color: theme.palette.error.main,
  },
}));

class SignUpWithEmailComp extends React.Component {
  state = {
    email: '',
    emailEntered: false,
    name: '',
    nameEntered: false,
    password: '',
    passwordEntered: false,
    confirmPassword: '',
    confirmPasswordEntered: false,
  };

  handleSignUp = e => {
    e.preventDefault();

    const { email, name, password } = this.state;

    captureEvent({
      name: 'SIGNUP_FORM_SUBMISSION',
      data: [{ key: 'email', value: email }],
    });

    this.props.signUp({
      variables: {
        input: { email, name, password },
      },
    });
  };

  render() {
    const {
      email,
      emailEntered,
      name,
      nameEntered,
      password,
      passwordEntered,
      confirmPassword,
      confirmPasswordEntered,
    } = this.state;
    const { classes } = this.props;

    const isPasswordValid = password.length >= 6;
    const isConfirmPasswordValid = password === confirmPassword;
    const isNameValid = name.trim().length > 0;
    const isEmailValid = isEmail(email);

    return (
      <form onSubmit={this.handleSignUp} className={classes.form}>
        <div className={classes.field}>
          <TextField
            autoComplete='username'
            // error={emailEntered && !isEmailValid}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='email'
            label='Email'
            id='email'
            type='email'
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
            onKeyUp={() => this.setState({ emailEntered: true })}
          />

          {emailEntered && !isEmailValid && (
            <Typography className={classes.errorText}>
              Invalid email address
            </Typography>
          )}
        </div>

        <div className={classes.field}>
          <TextField
            // error={nameEntered && !isNameValid}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='name'
            label='Name'
            id='name'
            type='name'
            value={name}
            onChange={e => this.setState({ name: e.target.value })}
            onKeyUp={() => this.setState({ nameEntered: true })}
          />

          {nameEntered && !isNameValid && (
            <Typography className={classes.errorText}>
              Empty name now allowed
            </Typography>
          )}
        </div>

        <div className={classes.field}>
          <TextField
            autoComplete='new-password'
            // error={passwordEntered && !isPasswordValid}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            id='password'
            type='password'
            value={password}
            onChange={e => this.setState({ password: e.target.value })}
            onKeyUp={() => this.setState({ passwordEntered: true })}
          />
          {passwordEntered && !isPasswordValid && (
            <Typography className={classes.errorText}>
              Password should be at least 6 characters long
            </Typography>
          )}
        </div>

        <div className={classes.field}>
          <TextField
            autoComplete='new-password'
            // error={confirmPasswordEntered && !isConfirmPasswordValid}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='confirmPassword'
            label='Confirm Password'
            id='confirmPassword'
            type='password'
            value={confirmPassword}
            onChange={e => this.setState({ confirmPassword: e.target.value })}
            onKeyUp={() => this.setState({ confirmPasswordEntered: true })}
          />
          {confirmPasswordEntered && !isConfirmPasswordValid && (
            <Typography className={classes.errorText}>
              Does not match with the password
            </Typography>
          )}
        </div>

        <Button
          variant='contained'
          type='submit'
          disabled={!isConfirmPasswordValid || !isNameValid || !isEmailValid}
          className={classes.submit}
        >
          Sign Up
        </Button>
      </form>
    );
  }
}

export const SignUpWithEmailForm = withStyles(styles)(SignUpWithEmailComp);
