import React from 'react';
import {
  Button,
  TextField,
  createStyles,
  withStyles,
  Typography,
} from '@material-ui/core';
import { captureEvent } from '../../utils/captureEvent';

const styles = createStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorText: {
    fontSize: '0.8em',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  field: {
    width: '100%',
    position: 'relative',
    paddingBottom: '8px',
    marginBottom: 0,
    color: theme.palette.error.main,
  },
}));

class ChangePasswordFormComp extends React.Component {
  state = {
    password: '',
    passwordEntered: false,
    confirmPassword: '',
    confirmPasswordEntered: false,
  };

  handleChangePassword = e => {
    e.preventDefault();

    captureEvent({
      name: 'CHANGE_PASSWORD_FORM_SUBMISSION',
      data: [],
    });

    this.props.changePassword({
      variables: {
        input: {
          confirmPassword: this.state.confirmPassword,
          password: this.state.password,
          resetPasswordSecret: this.props.secret,
        },
      },
    });
  };

  render() {
    const {
      password,
      passwordEntered,
      confirmPassword,
      confirmPasswordEntered,
    } = this.state;
    const { classes } = this.props;

    const isPasswordValid = password.length >= 6;
    const isConfirmPasswordValid =
      isPasswordValid && password === confirmPassword;

    return (
      <form
        onSubmit={this.handleChangePassword}
        className={this.props.classes.form}
      >
        <div className={classes.field}>
          <TextField
            autoComplete='new-password'
            // error={passwordEntered && !isPasswordValid}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label='Enter new password'
            id='password'
            type='password'
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value })}
            onKeyUp={() => this.setState({ passwordEntered: true })}
          />
          {passwordEntered && !isPasswordValid && (
            <Typography className={classes.errorText}>
              Password should be at least 6 characters long
            </Typography>
          )}
        </div>

        <div className={classes.field}>
          <TextField
            autoComplete='new-password'
            // error={confirmPasswordEntered && !isConfirmPasswordValid}
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='confirmPassword'
            label='Enter password again'
            id='confirmPassword'
            type='password'
            value={this.state.confirmPassword}
            onChange={e => this.setState({ confirmPassword: e.target.value })}
            onKeyUp={() => this.setState({ confirmPasswordEntered: true })}
          />
          {confirmPasswordEntered && !isConfirmPasswordValid && (
            <Typography className={classes.errorText}>
              Does not match with the password
            </Typography>
          )}
        </div>

        <Button
          variant='contained'
          type='submit'
          disabled={!isConfirmPasswordValid}
          className={this.props.classes.submit}
        >
          Change Password
        </Button>
      </form>
    );
  }
}

export const ChangePasswordForm = withStyles(styles)(ChangePasswordFormComp);
