import gql from 'graphql-tag';
import jsonwebtoken from 'jsonwebtoken';
import qs from 'query-string';
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Container, makeStyles } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { isMongoId } from 'validator';
import { Failure } from '../../components/Failure';
import { Loading } from '../../components/Loading';
import { Success } from '../../components/Success';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const VERIFY_ACCOUNT_MUTATION = gql`
  mutation VerifyAccount($input: VerifyAccountInput!) {
    result: verifyAccount(input: $input) {
      verified
      error {
        message
      }
    }
  }
`;

export const VerifyAccount = props => {
  const secret = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  }).secret;

  const classes = useStyles();

  const [error, setError] = useState(null);

  const [verify, { loading, data, error: gqlError }] = useMutation(
    VERIFY_ACCOUNT_MUTATION,
    {
      onCompleted: data => {
        const { verified, error: mutationError } = data.result;

        if (!verified) {
          setError(
            new Error(
              (mutationError && mutationError.message) || 'Unknown error',
            ),
          );
        }
      },
      onError: error => {
        Sentry.captureException(error);
        setError(error);
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    // this looks like a bit verbose but onCompletion/onError prop of useMutation options
    // is a callback (i.e. not synchronous) which causes mutation to be
    // executed twice if we rely on a error/success state set from within it.
    const verified = data && data.result && data.result.verified;
    const mutationError = data && data.result && data.result.error;
    const hasErrored = mutationError || gqlError;

    if (!hasErrored && !loading && !verified) {
      try {
        const { userID } = jsonwebtoken.decode(secret);

        if (!isMongoId(userID)) {
          throw new Error('invalid userID');
        }
      } catch (secretError) {
        setError(
          new Error(
            'Unable to verify user. Please use the link in your email and try again.',
          ),
        );
      }

      verify({
        variables: { input: { verificationSecret: secret } },
      });
    }
  }, [error, loading, secret, verify, data, gqlError]);

  const verified = data && data.result && data.result.verified;

  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        {loading && (
          <Loading messages={['Please wait till we verify your account.']} />
        )}
        {verified && (
          <Success
            title='Account verified successfully!'
            messages={[
              'Thank you for verifying your account. You can now log into your account.',
              'You may close this page.',
            ]}
          />
        )}
        {error && <Failure messages={[error.message]} />}
      </div>
    </Container>
  );
};
