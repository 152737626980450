import React from 'react';
import { Typography } from '@material-ui/core';

export const Success = ({ title, messages }) => {
  return (
    <>
      <Typography variant='h6'>{title}</Typography>
      {messages.map((m, idx) => (
        <Typography variant='body1' key={idx} paragraph>
          {m}
        </Typography>
      ))}
    </>
  );
};

Success.defaultProps = {
  title: 'Success!',
  messages: [],
};
