import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

export const Failure = ({ messages, retry }) => {
  const classes = useStyles();

  return (
    <div>
      {messages.map((m, idx) => (
        <Typography variant='body1' color='error' key={idx} paragraph>
          {m}
        </Typography>
      ))}

      {typeof retry === 'function' && (
        <Button variant='contained' onClick={retry} className={classes.button}>
          Retry
        </Button>
      )}
    </div>
  );
};

Failure.defaultProps = {
  messages: ['Unknown error'],
};
