import React from 'react';
import { isEmail } from 'validator';
import { createStyles, withStyles, Button, TextField } from '@material-ui/core';
import { captureEvent } from '../../utils/captureEvent';

const styles = createStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorText: {
    fontSize: '0.8em',
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  field: {
    width: '100%',
    position: 'relative',
    paddingBottom: '8px',
    marginBottom: 0,
    color: theme.palette.error.main,
  },
}));

class ForgotPasswordFormComp extends React.Component {
  state = {
    email: '',
    emailEntered: false,
  };

  handleSubmit = e => {
    e.preventDefault();

    captureEvent({
      name: 'FORGOT_PASSWORD_FORM_SUBMISSION',
      data: [{ key: 'email', value: this.state.email }],
    });

    this.props.forgotPassword({
      variables: {
        input: {
          email: this.state.email,
        },
      },
    });
  };

  render() {
    const { classes } = this.props;
    const { email } = this.state;

    const isValidEmail = isEmail(email);

    return (
      <form onSubmit={this.handleSubmit} className={classes.form}>
        <TextField
          autoComplete='off'
          // error={emailEntered && !isValidEmail}
          variant='outlined'
          margin='normal'
          fullWidth
          name='email'
          label='Enter your email address'
          id='email'
          type='email'
          value={email}
          onChange={e => this.setState({ email: e.target.value })}
        />

        <Button
          variant='contained'
          type='submit'
          disabled={!isValidEmail}
          className={classes.submit}
        >
          Email password reset link
        </Button>
      </form>
    );
  }
}

export const ForgotPasswordForm = withStyles(styles)(ForgotPasswordFormComp);
