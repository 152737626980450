import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Success } from '../../components/Success';
import { Avatar, Container, Typography, makeStyles } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import * as Sentry from '@sentry/browser';
import { Failure } from '../../components/Failure';
import { Loading } from '../../components/Loading';
import { SignUpWithEmailForm } from './SignUpWithEmailForm';
import { captureEvent } from '../../utils/captureEvent';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const SIGN_UP_WITH_EMAIL = gql`
  mutation SignUpWithEmail($input: SignUpWithEmailInput!) {
    result: signUpWithEmail(input: $input) {
      created
      error {
        message
      }
    }
  }
`;

export function SignUp(props) {
  const classes = useStyles();

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [signUp, { loading }] = useMutation(SIGN_UP_WITH_EMAIL, {
    onCompleted: data => {
      const { created, error: mutationError } = data.result;

      if (created) {
        setSuccess(true);
      } else {
        setError(
          new Error(
            (mutationError && mutationError.message) || 'Unknown error',
          ),
        );
      }
    },
    onError: error => {
      Sentry.captureException(error);

      setError(error);
    },
  });

  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        {!success && (
          <>
            <Avatar className={classes.avatar}>
              <Edit />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign up for an Account
            </Typography>
          </>
        )}

        <div className={classes.content}>
          {loading && <Loading />}

          {success && (
            <Success
              title='Sign up successful!'
              messages={[
                'Your account was created successfully and a confirmation email has been dispatched to you.',
                'If you do not receive it in the next 5 minutes, please check your spam box.',
                'You may now close this page.',
              ]}
            />
          )}

          {error && (
            <Failure
              messages={[error.message]}
              retry={() => {
                captureEvent({ name: 'RETRY_SIGNUP', data: [] });
                setError(null);
                setSuccess(false);
              }}
            />
          )}

          {!loading && !error && !success && (
            <SignUpWithEmailForm {...props} signUp={signUp} />
          )}
        </div>
      </div>
    </Container>
  );
}
