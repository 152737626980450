import gql from 'graphql-tag';
import localForage from 'localforage';
import UAParser from 'ua-parser-js';
import * as Sentry from '@sentry/browser';
import cuid from 'cuid';
import { getClient } from '../config/apollo';

const parser = new UAParser();

const { browser, device, os } = parser.getResult();

const SUBMIT_ANALYTIC_EVENTS_MUTATION = gql`
  mutation SubmitAnalyticEvents($input: SubmitAnalyticEventsInput!) {
    result: submitAnalyticEvents(input: $input) {
      events {
        eventID
      }
      error {
        message
      }
    }
  }
`;

let eventsQueue = [];

export async function captureEvent({ name, data }) {
  let deviceID = await localForage.getItem('deviceID');

  if (!deviceID) {
    deviceID = `${device.model ? `${device.model}, ` : ''}${
      os.name ? `${os.name}, ` : ''
    }${browser.name ? `${browser.name}(${browser.version})` : ''} - ${cuid()}`;

    await localForage.setItem('deviceID', deviceID);
  }

  const event = {
    name,
    data,
    appID: 'accounts.lithodomos.com',
    deviceID,
    timestamp: new Date().toISOString(),
    eventID: cuid(),
  };

  eventsQueue.push(event);

  const client = getClient();

  try {
    const response = await client.mutate({
      mutation: SUBMIT_ANALYTIC_EVENTS_MUTATION,
      variables: {
        input: { events: eventsQueue },
      },
    });

    const { events, error } = response.data.result;

    if (!error) {
      eventsQueue = eventsQueue.filter(
        e => !events.find(captured => captured.eventID === e.eventID),
      );
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
