import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { from } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { HttpLink } from 'apollo-link-http';

let client;

export function getClient() {
  if (client) {
    return client;
  }

  const cache = new InMemoryCache();
  const retryLink = new RetryLink();
  const httpLink = new HttpLink({
    uri: 'https://janus.lithodomos.com/graphql',
    credentials: 'same-origin',
  });

  client = new ApolloClient({
    cache,
    link: from([retryLink, httpLink]),
  });

  return client;
}
