import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { CssBaseline } from '@material-ui/core';
import styled from 'styled-components';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ChangePassword } from './routes/changePassword/ChangePassword';
import { VerifyAccount } from './routes/verifyAccount/VerifyAccount';
import { ForgotPassword } from './routes/forgotPassword/ForgotPassword';
import { SignUp } from './routes/signUp/SignUp';
import { RedirectToHome } from './components/RedirectToHome';
import { RouteListener } from './components/RouteListener';
import { getClient } from './config/apollo';

export const COLORS = {
  primaryBackgroundColor: '#fff',
  primaryTextColor: '#222',
  secondaryTextColor: '#777',
  tertiaryTextColor: '#fff',
  activeColor: 'rgb(224, 56, 133)',
  secondaryBackgroundColor: '#0f1620',
  tertiaryColor: 'rgba(93, 148, 255, 0.84)',
};

export const HEADER_HEIGHT = 50;
export const HEADER_WIDTH = 240;

const HeaderContainer = styled.header`
  display: flex;
  height: ${HEADER_HEIGHT}px;
  width: 100%;

  background-color: white;
  border-top: 4px solid ${COLORS.activeColor};
`;

const HeaderLogo = styled.div`
  display: inline-flex;
  height: ${HEADER_HEIGHT}px;
  width: ${HEADER_WIDTH}px;
  background-color: ${COLORS.secondaryBackgroundColor};
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 240px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 25px 20px;
    border-color: transparent transparent transparent
      ${COLORS.secondaryBackgroundColor};
  }
`;

const HeaderImage = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  height: ${HEADER_HEIGHT}px;
`;

function App() {
  return (
    <ApolloProvider client={getClient()}>
      <Router>
        <RouteListener />

        <div className='App'>
          <HeaderContainer>
            <HeaderLogo
              onClick={() =>
                (window.location.href = 'https://www.lithodomos.com')
              }
            >
              <HeaderImage>
                <img
                  alt={''}
                  src='/lithodomos-icon-logo-white.png'
                  height={36}
                />
                <img
                  alt={''}
                  src='/lithodomos-text-logo-white.png'
                  height={36}
                />
              </HeaderImage>
            </HeaderLogo>

            {/* <ButtonsContainer>
              <HeaderButtons>
                <HeaderButton to='/signup'>Sign Up</HeaderButton>
                <HeaderButton to='/forgot'>Forgot Password</HeaderButton>
              </HeaderButtons>
            </ButtonsContainer> */}
          </HeaderContainer>

          <CssBaseline />
          <Switch>
            <Route path='/signup' exact component={SignUp} />
            <Route path='/reset_pwd' exact component={ChangePassword} />
            <Route path='/verify' exact component={VerifyAccount} />
            <Route path='/forgot' exact component={ForgotPassword} />
            <Route path='*' component={RedirectToHome} />
          </Switch>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
