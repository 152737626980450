import gql from 'graphql-tag';
import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Avatar, Container, Typography, makeStyles } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import * as Sentry from '@sentry/browser';
import { Failure } from '../../components/Failure';
import { Loading } from '../../components/Loading';
import { Success } from '../../components/Success';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { captureEvent } from '../../utils/captureEvent';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  content: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));

const SEND_RESET_PASSWORD_LINK = gql`
  mutation SendResetPasswordLink($input: SendResetPasswordLinkInput!) {
    result: sendResetPasswordLink(input: $input) {
      sent
      error {
        message
      }
    }
  }
`;

export function ForgotPassword() {
  const classes = useStyles();
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

  const [forgotPassword, { loading }] = useMutation(SEND_RESET_PASSWORD_LINK, {
    onError: error => {
      Sentry.captureException(error);

      setError(error.message);
    },
    onCompleted: data => {
      const { sent, error: mutationError } = data.result;

      if (sent) {
        setSuccess(true);
      } else {
        setError(
          new Error(
            (mutationError && mutationError.message) || 'Unknown error',
          ),
        );
      }
    },
  });

  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        {!success && (
          <>
            <Avatar className={classes.avatar}>
              <Edit />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Forgot password
            </Typography>
          </>
        )}
        <div className={classes.content}>
          {loading && <Loading />}
          {success && (
            <Success
              title='Application successful!'
              messages={[
                'An email with password reset instructions was successfully sent to you.',
                'If you do not receive it in the next 5 minutes, please check your spam box.',
              ]}
            />
          )}
          {error && (
            <Failure
              messages={[error.message]}
              retry={() => {
                captureEvent({ name: 'RETRY_FORGOT_PASSWORD', data: [] });
                setError(null);
                setSuccess(false);
              }}
            />
          )}
          {!loading && !error && !success && (
            <ForgotPasswordForm forgotPassword={forgotPassword} />
          )}
        </div>
      </div>
    </Container>
  );
}
