import React from 'react';
import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
}));

export const Loading = ({ messages }) => {
  const classes = useStyles();

  return (
    <Container>
      <CircularProgress className={classes.progress} />
      {messages.map((m, idx) => (
        <Typography variant='body1' key={idx} paragraph>
          {m}
        </Typography>
      ))}
    </Container>
  );
};

Loading.defaultProps = {
  messages: [],
};
